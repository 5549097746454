<template>
  <vs-card>
    <div
      slot:header
      class="flex flex-no-wrap w-full justify-end items-center"
    >
      <!-- <div class="mr-2">
        <vs-button
          v-if="canAssignClinic"
          @click="onboardNurseHandler"
          icon-pack="feather"
          icon="icon-edit"
          >Assign</vs-button
        >
        <vs-button v-if="canCreate" @click="redirectToCreateOrg"
          >Create</vs-button
        >
      </div> -->
      <!-- <div
              class="mr-2"
              v-if="
                $store.state.AppActiveUser.userType === 'superAdmin' ||
                ($store.state.AppActiveUser.userType === 'admin' &&
                  canCreate &&
                  !franchiseId)
              "
            >
              <vs-button
                @click="onboardNurseHandler"
                icon-pack="feather"
                icon="icon-edit"
                >Onboard Nurse</vs-button
              >
            </div> -->
    </div>
    <div>
      <div id="data-list-list-view" class="data-list-container">
        <div class="lg:hidden mt-3">
          <div class="mb-2 flex flex-wrap">
            <div class="w-1/2 mb-2 pr-2">
              <vs-select
                placeholder="Select Year"
                style="width: 100%"
                autocomplete
                v-model="dataTableParams.year"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in yearDropdown"
                />
              </vs-select>
            </div>
            <div class="w-1/2 mb-2 pr-2">
              <vs-select
                autocomplete
                v-model="dataTableParams.month"
                style="width: 100%"
              >
                <vs-select-item
                  v-for="(item, index) in monthDropdown"
                  :key="item + '' + index"
                  :value="item.value"
                  :text="item.name"
                />
              </vs-select>
            </div>

            <div class="w-1/2 mb-2 pr-2">
              <vs-select
                style="width: 100%"
                placeholder="Select Clinic"
                autocomplete
                v-model="dataTableParams.clinic"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.clinicName"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
            <div class="w-1/2 mb-2 pr-2">
              <vs-select
                style="width: 100%"
                autocomplete
                vs-multiple
                v-model="dataTableParams.limit"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
            <div class="w-full mb-2 pr-2">
              <vs-input
                icon="search"
                style="width: 100%"
                placeholder="Search"
                v-model="searchInputString"
              />
            </div>
          </div>
          <ul>
            <li :key="indextr" v-for="(tr, indextr) in users">
              <div
                class="rounded mb-2 p-2 mobile-text relative"
                style="border: 1px solid #e8e8e8"
              >
                <div>
                  <div>
                    <h4 class="mr-2 mobile-text">
                      Clinic:
                      {{
                        users[indextr].clinic
                          ? users[indextr].clinic
                          : "N/A" | capitalize
                      }}
                    </h4>
                  </div>
                  <div>
                    <h4 class="mobile-text">
                      Patient:
                      {{
                        users[indextr].patient
                          ? users[indextr].patient
                          : "N/A" | capitalize
                      }}
                    </h4>
                  </div>
                </div>
                <div class="mobile-text">
                  <p>
                    Amount:
                    {{
                      users[indextr].treatmentPlans[0].amount
                        | currency_formatter
                    }}
                  </p>
                </div>
                <div class="mobile-text">
                  <p>Date: {{ users[indextr].date }}</p>
                </div>
                <div class="mobile-text">
                  <p>Doctor: {{ users[indextr].doctor | capitalize }}</p>
                </div>
                <div class="mobile-text">
                  <p>Nurse: {{ users[indextr].nurse | capitalize }}</p>
                </div>
                <div class="absolute top-0 right-0">
                  <ViewTreatmentHistoryDetailButton
                    :checkInId="users[indextr].patientCheckinId"
                    :patientId="users[indextr].patientId"
                    :month="dataTableParams.month"
                    :year="dataTableParams.year"
                    :clinicId="dataTableParams.clinicId"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="users"
          @search="search"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div slot="header" class="flex flex-wrap flex-grow items-center">
            <div class="mb-2">
              <vs-select
                autocomplete
                vs-multiple
                id="pageSizeSelect"
                v-model="dataTableParams.limit"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in limitOptions"
                  :clearable="false"
                />
              </vs-select>
            </div>
            <div class="mb-2">
              <vs-select
                class="mr-2"
                placeholder="Select Year"
                autocomplete
                v-model="dataTableParams.year"
              >
                <vs-select-item
                  :key="index"
                  :value="item"
                  :text="item"
                  v-for="(item, index) in yearDropdown"
                />
              </vs-select>
            </div>
            <div class="mb-2">
              <vs-select
                class="mr-2"
                autocomplete
                v-model="dataTableParams.month"
              >
                <vs-select-item
                  v-for="(item, index) in monthDropdown"
                  :key="item + '' + index"
                  :value="item.value"
                  :text="item.name"
                />
              </vs-select>
            </div>
            <div class="mb-2">
              <vs-select
                style="position: relative"
                placeholder="Select Clinic"
                autocomplete
                v-model="dataTableParams.clinic"
                class="mr-2"
              >
                <vs-select-item
                  :key="index"
                  :value="item.clinicId"
                  :text="item.clinicName"
                  v-for="(item, index) in clinics"
                />
              </vs-select>
            </div>
          </div>

          <template slot="thead">
            <vs-th sort-key="clinicName">DATE</vs-th>
            <vs-th sort-key="patient">CLINIC</vs-th>
            <vs-th sort-key="patient">PATIENT</vs-th>
            <vs-th sort-key="nurse">NURSE</vs-th>
            <vs-th sort-key="docotor">DOCTOR</vs-th>
            <vs-th sort-key="amounts">AMOUNTS</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].date">{{ data[indextr].date }}</vs-td>
              <vs-td :data="data[indextr].clinic">{{
                data[indextr].clinic | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].patient">{{
                data[indextr].patient | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].nurse">{{
                data[indextr].nurse | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].nurse">{{
                data[indextr].doctor | capitalize
              }}</vs-td>
              <vs-td :data="data[indextr].nurse">{{
                data[indextr].treatmentPlans[0].amount | currency_formatter
              }}</vs-td>
              <vs-td :data="data[indextr]._id">
                <ViewTreatmentHistoryDetailButton
                  :checkInId="data[indextr].patientCheckinId"
                  :patientId="data[indextr].patientId"
                  :month="dataTableParams.month"
                  :year="dataTableParams.year"
                  :clinicId="dataTableParams.clinicId"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <div
          class="
            m-2
            flex
            sm:justify-between
            justify-center
            items-center
            flex-wrap"
          style="height: 35px padding-bottom: 30px"
        >
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import ViewTreatmentHistoryDetailButton from "@/views/components/viewTreatmentHistoryDetailButton";

export default {
  name: "organization-table",
  components: {
    ViewTreatmentHistoryDetailButton,
  },
  props: {
    canDelete: {
      type: Boolean,
      default: false,
    },
    viewRoute: {
      type: String,
      default: "FranchiseNurseDetails",
    },
    viewDetailRoute: {
      type: String,
      default: "SuperAdminNurseDetailView",
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
    createRoute: {
      type: String,
      default: "FranchiseNurseCreate",
    },
    canAssignClinic: {
      type: Boolean,
      default: true,
    },
    franchiseId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      noDataText: "Loading..",
      orgCount: 0,
      totalDocs: 0,
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        id: null,
        year: new Date().getFullYear(),
        month: `${
        (new Date().getMonth() + 1) < 10
          ? `0${new Date().getMonth() + 1}`
          : new Date().getMonth() + 1
      }`,
        clinic: null,
        userId: "",
      },
      users: [],
      serverResponded: false,
      limitOptions: [5, 10, 25, 50, 100],
      filterList: [
        { value: "All", label: "All Nurses" },
        { value: "Approved", label: "Approved" },
        { value: "Unapproved", label: "Unapproved" },
      ],
      clinics: [{ clinicId: null, clinicName: "All Clinics" }],
      // monthDropdown: [
      //   { name: "All Months", value: null },
      //   { name: "January", value: "Jan" },
      //   { name: "February", value: "Feb" },
      //   { name: "March", value: "Mar" },
      //   { name: "April", value: "April" },
      //   { name: "May", value: "May" },
      //   { name: "June", value: "June" },
      //   { name: "July", value: "July" },
      //   { name: "August", value: "Aug" },
      //   { name: "September", value: "Sep" },
      //   { name: "October", value: "Oct" },
      //   { name: "November", value: "Nov" },
      //   { name: "December", value: "Dec" },
      // ],
      monthDropdown: [
        { name: "All Months", value: null },
        { name: "January", value: "01" },
        { name: "February", value: "02" },
        { name: "March", value: "03" },
        { name: "April", value: "04" },
        { name: "May", value: "05" },
        { name: "June", value: "06" },
        { name: "July", value: "07" },
        { name: "August", value: "08" },
        { name: "September", value: "09" },
        { name: "October", value: "10" },
        { name: "November", value: "11" },
        { name: "December", value: "12" },
      ],
      yearDropdown: [],
      awaitingSearch: null,
      permissionErrorMessage: 'Permission needed! please contact administrator.'
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchOrganizationTreatments",
      "fetchOrganizationClinics",
    ]),
    getYearDropdown() {
      this.yearDropdown = [];
      const maxYear = moment(new Date()).format("YYYY");
      const limitYear = maxYear - 30;
      for (let i = maxYear; i >= limitYear; i--) {
        this.yearDropdown.push(i.toString());
      }
    },
    search(searching) {
      this.searchInputString = searching;
    },
    moment(date) {
      return moment(date);
    },
    getOrganizationTeatmentList() {
      this.$vs.loading();
      this.fetchOrganizationTreatments(this.dataTableParams).then((res) => {
        this.noDataText = "No Treatments Available";
        this.serverResponded = true;
        this.users = res.data.data.docs;
        this.totalDocs =
          res.data.data.pagination.total || res.data.data.pagination[0].total;
        this.page =
          res.data.data.pagination.page || res.data.data.pagination[0].page;
        this.currentPage =
          res.data.data.pagination.page || res.data.data.pagination[0].page;
        if (res.data.data && res.data.data.orgCount) {
          if (
            this.$store.state.AppActiveUser.userType === "franchise" ||
            this.$store.state.AppActiveUser.userType === "subFranchise"
          )
            this.orgCount = res.data.data.orgCount;
          if (
            this.$store.state.AppActiveUser.userType === "clinic" ||
            this.$store.state.AppActiveUser.userType === "subClinic"
          )
            this.orgCount = 1;
        }
      }).catch(err => {
        if (err.status == 403) this.noDataText = this.permissionErrorMessage
      }).finally(() => this.$vs.loading.close());
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.getOrganizationTeatmentList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getOrganizationTeatmentList();
    },
    handleSort(key, active) {
      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getOrganizationTeatmentList();
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getOrganizationTeatmentList();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getOrganizationTeatmentList();
      }
    },
    "dataTableParams.year": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.year = newfilterVal;
        this.getOrganizationTeatmentList();
      }
    },
    "dataTableParams.month": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.month = newfilterVal;
        this.getOrganizationTeatmentList();
      }
    },
    "dataTableParams.clinic": function (newfilterVal, oldFilterVal) {
      if (newfilterVal !== oldFilterVal) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.clinic = newfilterVal;
        this.getOrganizationTeatmentList();
      }
    },
  },
  async created() {
    this.dataTableParams.userId = this.$route.params.nurseId
      ? this.$route.params.nurseId
      : this.$store.state.AppActiveUser.id;
    this.dataTableParams.id = this.$route.params.organizationId
      ? this.$route.params.organizationId
      : localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        );

    try {
      const res = await this.fetchOrganizationClinics({
        id: this.dataTableParams.id,
      });
      
      res.data.data.docs.forEach((clinic) => {
        this.clinics.push({
          clinicId: clinic._id,
          clinicName: clinic.clinicName,
        });
      });

      this.getYearDropdown();
      this.getOrganizationTeatmentList();
    } catch(e) {
      if (e.status == 403) this.noDataText = this.permissionErrorMessage
    }
  },
};
</script>
<style scoped>
.mobile-text {
  color: #164d3d;
  margin: 0.25rem;
}
</style>
